
import gptModule from '@/store/modules/gptModule';
import { getComponent, sleep } from '@/utils/helpers';
import { Debounce } from 'vue-debounce-decorator';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    // GptRecommendation: () => getComponent("gpt/GptRecommendation"),
  },
})
export default class GptRecommendations extends Vue {
  @Prop({ type: Object, required: true }) tasq?: any;

  activeTab = '';

  // recommendation: any =  {status: ''}

  status = '';

  recommendation: any = {
    status: '',
    paragraphs: '',
  };

  trackingID = ''

  get rawRecommendation() {
    return gptModule.recommendations ? gptModule.recommendations : '';
  }

  @Watch('rawRecommendation',{immediate: true})
  updateRecommendation(data) {
    this.recommendation = {
      status: '',
      paragraphs: this.formattedRecommendations(data),
    };
  }


  destory(){
    this.userOpensRecommendation()
  }


  async mounted() {
    await sleep(3000)
    this.userOpensRecommendation()
  }



  userOpensRecommendation(){
    if(this.trackingID){
      this.$emit('open-recommendation',this.trackingID)
    }
  }


  saveRecommndation(status){
    this.status = status
    const payload = {
      comment: gptModule.gptQueryText,
      isGoodCatch: status,
      trackingID: this.trackingID
    }
    this.$emit('save-recommendation',payload)
  }

  formattedRecommendations(recommendation) {
    if (!recommendation.length) {
      return;
    }

    // Accumulate data from lines starting with 'data:'
    let accumulatedData = '';
    recommendation.split('\n').forEach((line) => {
      if (line.startsWith('data: tracking_id:')) {
        const trackingId = line.split('tracking_id:');
        if(trackingId && trackingId[1])
        console.log('Tracking ID:', trackingId[1]);
      this.trackingID = trackingId[1].replace(/(\r)/gm, '');
    }else if (line.startsWith('data:')) {
        accumulatedData += line.slice(6).replace(/(\r)/gm, '');
      }
    });

    console.log(accumulatedData);

    let lines = [];

    let newData = '';

    if (accumulatedData.includes(':-') || accumulatedData.includes('steps:')) {
      let splitIndex;

      if (accumulatedData.includes(':-')) {
        splitIndex = accumulatedData.indexOf(':-') + 2; // 2 is the length of ":-"
      } else if (accumulatedData.includes('steps:')) {
        splitIndex = accumulatedData.indexOf('steps:') + 6;
      }

      const firstLine = accumulatedData.substring(0, splitIndex);
      lines[0] = `<p class="text-lg font-semibold">${firstLine}</p>`;

      if (splitIndex < accumulatedData.length) {
        newData = accumulatedData.substring(splitIndex);
      }
    } else {
      newData = accumulatedData;
    }



    // Process the first line

    if (newData) {
      const otherLines = newData
      .replace(/(\.[1-8]\.)/g, '.-')
        .split('.- ')
        .filter((line) => line.trim().length > 0)
        .map((line) => line.trim());
      for (let i = 0; i < otherLines.length; i++) {
        // Add div and span elements for styling
        if(otherLines[i].length){
          const text = otherLines[i].replace(/^\-/, ' ')
          lines.push(
          `<div class="hidden bullet-point"></div><span class="bullet-text list-item mx-3">${text}</span>`
        );
        }

      }
    }

    if (lines.length === 0) {
      return '';
    }

    // Process remaining lines

    return lines.join('<br/>');
  }

}
